var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Collapsible"},[_c('button',{class:{
      Collapsible__trigger: true,
      'Collapsible__trigger--open': !_vm.transitioning && _vm.open,
      'Collapsible__trigger--closed': !_vm.transitioning && !_vm.open,
      'Collapsible__trigger--transitioning': _vm.transitioning,
    },on:{"click":_vm.handleClick}},[(_vm.open)?_vm._t("trigger",[_c('span',{staticClass:"Collapsible__triggerLabel"},[_vm._v(" "+_vm._s(!_vm.open ? _vm.closedLabel : _vm.openLabel)+" ")])]):_vm._e(),(!_vm.open)?_vm._t("closedTrigger",[_vm._t("trigger",[_c('span',{staticClass:"Collapsible__triggerLabel"},[_vm._v(_vm._s(!_vm.open ? _vm.closedLabel : _vm.openLabel))])])]):_vm._e()],2),_c('div',{class:{
      Collapsible__content: true,
      'Collapsible__content--open': !_vm.transitioning && _vm.open,
      'Collapsible__content--closed': !_vm.transitioning && !_vm.open,
      'Collapsible__content--transitioning': _vm.transitioning,
    },style:({
      height: _vm.height,
      transitionProperty: 'height',
      transitionDuration: _vm.transitionDuration,
      transitionTimingFunction: _vm.transitionTimingFunction,
      transitionDelay: _vm.transitionDelay,
    }),on:{"transitionend":_vm.handleEnd}},[_c('div',{ref:"inner",staticClass:"Collapsible__contentInner"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }